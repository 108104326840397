import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import "./Services.css";
import sections from "../../constants/data";
import SingleService from './SingleService';
import TrackVisibility from 'react-on-screen';
import 'animate.css';

const Services = () => {
  const controls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      // Adjust the scroll position based on your requirement
      if (scrollY > 200) {
        controls.start({
          opacity: 1,
          y: 0,
          transition: { duration: 0.8 },
        });
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [controls]);

  return (
    <motion.div animate={controls} id='test' initial={{ opacity: 0, y: 20 }}>
      <section className='services section-p bg-md-black'>
        <motion.div>
          <TrackVisibility offset={50} partialVisibility>
            {({ isVisible }) => (
              <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                <div className='container'>
                  <div className='services-content'>
                    <svg width="1em" height="1em">
                      <linearGradient id="blue-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
                        <stop stopColor="#55b3d5" offset="0%" />
                        <stop stopColor="#5764de" offset="100%" />
                      </linearGradient>
                    </svg>

                    <div className='item-list grid text-white text-center'>
                      {sections.services.map(service => (
                        <SingleService service={service} key={service.id} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </TrackVisibility>
        </motion.div>

      </section>
    </motion.div>
  );
}

export default Services;
