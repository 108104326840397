import './Technologys.css';
import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import TrackVisibility from 'react-on-screen';
import 'animate.css';
import react from '../../assets/images/tech-img/react.png'
import python from '../../assets/images/tech-img/python.png'
import java from '../../assets/images/tech-img/java.png'
import postgres from '../../assets/images/tech-img/Postgres.png'
import bigdata from '../../assets/images/tech-img/bigdata.png'
import figma from '../../assets/images/tech-img/figma.png'
import dotnet from '../../assets/images/tech-img/dotnet.png'
import monogo from '../../assets/images/tech-img/mongodb.png'
import devops from '../../assets/images/tech-img/devops.png'
import angular from '../../assets/images/tech-img/angular.png'
import flutter from '../../assets/images/tech-img/flutter.png'
import sql from '../../assets/images/tech-img/sql-server.png'


const Technologys = () => {

    const tech_data = [
        {
            tech_name: "React",
            color: "#61dafb",
            image: react
        },
        {
            tech_name: "Angular",
            color: "#dd0031",
            image: angular
        },
        {
            tech_name: "Flutter",
            color: '#02539A',
            image: flutter
        },
        {
            tech_name: "Figma",
            color: "#001    ",
            image: figma
        },
        {
            tech_name: "Python",
            color: "#FFD342",
            image: python
        },
        {
            tech_name: "Java",
            color: '#EC2025',
            image: java
        },
        {
            tech_name: "Dot NET",
            color: "#5C2D91",
            image: dotnet
        },
        {
            tech_name: "Postgres",
            color: "#336791",
            image: postgres
        },
        {
            tech_name: "MongoDB",
            color: "#12924F",
            image: monogo
        },
        {
            tech_name: "MSSQL",
            color: "#E15644",
            image: sql
        },
        {
            tech_name: "BigData",
            color: "#0275EA",
            image: bigdata
        },
        {
            tech_name: "DevOps",
            color: '#EE4C8E',
            image: devops
        },
        
    ];

    const slicedTechData = tech_data.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / 4);

        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [];
        }

        resultArray[chunkIndex].push(item);
        return resultArray;
    }, []);

    return (
        <div id='tech' className="bubble-container services section-p bg-md-black">
            <motion.div className='tech-header' initial="initial" animate="animate" >
                <motion.div className='section-t' initial="hidden" animate="visible">
                    <h3 className='tech-title'>Technologies & Tools</h3>
                </motion.div>
                <motion.div>
                    <TrackVisibility offset={50} partialVisibility>
                        {({ isVisible }) => (
                            <div className={isVisible ? "tech-page animate__animated animate__zoomIn" : ""}>
                                {slicedTechData.map((row, rowIndex) => (
                                    <div key={rowIndex} className="flex flex-row justify-around flex-wrap mt-4 gap-5 items-center">
                                        {row.map((tech, index) => (
                                            <motion.div
                                                key={index}
                                                className={`circle ${tech.tech_name}`}
                                                style={{ color: tech.color }}
                                                initial={{ y: -50, opacity: 0 }}
                                                animate={{
                                                    y: 0,
                                                    opacity: 1,
                                                    scale: isVisible ? 1.1 : 1, // Apply scale only if visible
                                                    rotate: 360,
                                                }}
                                                transition={{ duration: 0.5, delay: index * 0.1, yoyo: Infinity }}
                                            >
                                                <img className='tech-img' src={tech.image} alt='' />
                                                <p>{tech.tech_name}</p>
                                            </motion.div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        )}
                    </TrackVisibility>
                </motion.div>
            </motion.div>
        </div>
    );
};

export default Technologys;
