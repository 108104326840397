import React from 'react';
import "./Contact.css";
import images from '../../constants/images';
import { Formik } from 'formik';
import Info from './Info';
import Map from './Map';

const Contact = () => {
    return (
        <section className='contact section-p-top bg-black' id="contacts">
            <div className='container'>
                <div className='contact-content grid text-center'>
                    <div className='contact-left'>
                        <div className='section-t'>
                            <h3>Let's Talk?</h3>
                            <p className='text'>Feel free to say hi! We are friendly and social</p>
                        </div>

                        <Formik
                            initialValues={{ name: "", email: '', message: '', phonenumber: '' }}
                            validate={values => {
                                const errors = {};

                                if (!values.name) {
                                    errors.name = "Name is required";
                                } else if (!/^[A-Za-z\s]*$/.test(values.name)) {
                                    errors.name = "Invalid name format";
                                }

                                if (!values.email) {
                                    errors.email = 'Email is required';
                                } else if (
                                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                ) {
                                    errors.email = 'Invalid email address';
                                }

                                if (!values.message) {
                                    errors.message = "message is required";
                                }

                                if (!values.phonenumber) {
                                    errors.phonenumber = "Phone Number is required";
                                } else if (!/^\d{10}$/i.test(values.phonenumber)) {
                                    errors.phonenumber = "Invalid phone number";
                                }

                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                const mailtoLink = `mailto:Mohanapriya@Conprg.in?subject=Form Submission&body=Name: ${values.name}%0D%0AEmail: ${values.email}%0D%0APhonenumber: ${values.phonenumber}%0D%0AMessage: ${values.message}`;
                                window.location.href = mailtoLink;
                                setSubmitting(false);
                                // setTimeout(() => {
                                // alert(JSON.stringify(values, null, 2));
                                // }, 400);
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                /* and other goodies */
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className='form-elem'>
                                        {/* <p>Name</p> */}
                                        <input type="text" placeholder='Name' name="name" onChange={handleChange} onBlur={handleBlur} value={values.name} className="form-control" />
                                        <span className='form-control-text'>{errors.name && touched.name && errors.name}</span>
                                    </div>

                                    <div className='form-elem'>
                                        <input type="email" placeholder='Email' name="email" onChange={handleChange} onBlur={handleBlur} value={values.email} className="form-control" />
                                        <span className='form-control-text'>{errors.email && touched.email && errors.email}</span>
                                    </div>

                                    <div className='form-elem'>
                                        <input type="text" placeholder='Phone Number' name="phonenumber" onChange={handleChange} onBlur={handleBlur} value={values.phonenumber} className="form-control" />
                                        <span className='form-control-text'>{errors.phonenumber && touched.phonenumber && errors.phonenumber}</span>
                                    </div>

                                    <div className='form-elem'>
                                        <input type="text" placeholder='Message' name="message" onChange={handleChange} onBlur={handleBlur} value={values.message} className="form-control" />
                                        <span className='form-control-text'>{errors.message && touched.message && errors.message}</span>
                                    </div>

                                    <div className='flex flex-start'>
                                        <button type="submit" disabled={isSubmitting} className="submit-btn">contact us</button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>

                    <div className='contact-right'>
                        <img src={images.form_main_img} alt="" />
                    </div>
                </div>
            </div>

            <Map />
            <Info />
        </section>
    )
}

export default Contact
