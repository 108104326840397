import Navbar from '../Navbar/Navbar';
import "./Header.css";
import { FaPaperPlane } from "react-icons/fa";
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import samplevideo from '../../assets/videos/sample.mp4'

const Header = () => {

  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0
  });
  const [cursorVariant, setCursorVariant] = useState("default");


  useEffect(() => {
    const mouseMove = e => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY
      })
    }

    window.addEventListener("mousemove", mouseMove);

    return () => {
      window.removeEventListener("mousemove", mouseMove);
    }
  }, []);

  const variants = {
    default: {
      x: mousePosition.x - 16,
      y: mousePosition.y - 16,
    },
    text: {
      height: 100,
      width: 100,
      x: mousePosition.x - 75,
      y: mousePosition.y - 75,
      background: "#FA6E5A",
      mixBlendMode: "difference"
    }
  }

  const textEnter = () => setCursorVariant("text");
  const textLeave = () => setCursorVariant("default");

  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["Web & Mobile Development", "Consulting", "Corporate Training", "Application Services"];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <header className='header flex flex-center flex-column'>
      <Navbar />
      <motion.div className='container'>
        <motion.div className='header-content text-center flex flex-column'>
          <motion.div
            className='cursor'
            variants={variants}
            animate={cursorVariant}
          />
          <motion.div className="video-container">
            <video autoPlay muted loop>
              <source src={samplevideo} type="video/mp4" />
            </video>
            <motion.h1>{`Conprg Technology is`} <span className="txt-rotate" dataPeriod="1000"
              data-rotate='[ "Web & Mobile Development", "Consulting", "Corporate Training","Application Services" ]'>
              <span className="wrap">{text}</span></span></motion.h1>
            <motion.h1
              // onMouseEnter={textEnter}
              // onMouseLeave={textLeave}
              className='text-uppercase header-title'
            >
              IT solution Company
            </motion.h1>
          </motion.div>

          <p className='text-lead'>We offer ideas that raise your business above the expected.</p>
          <a href="/" className='btn header-btn btn-blue'>
            <FaPaperPlane /> <span>get started</span>
          </a>
        </motion.div>
      </motion.div>
    </header>
  )
}

export default Header