import React from 'react';
import "./Contact.css";

const Map = () => {
  return (
    <div className='map-content'>
        {/* eslint-disable-next-line */}
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.1854464273306!2d80.22435867409696!3d13.023859687296298!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52671868c778b1%3A0xd5fc58acb98dca4c!2sKaizen%20Techno%20Soft!5e0!3m2!1sen!2sin!4v1707777092551!5m2!1sen!2sin" width="1400" height="300" ></iframe>
        
    </div>
  )
}

export default Map
