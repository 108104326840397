import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import "./About.css";
import images from '../../constants/images';
import TrackVisibility from 'react-on-screen';
import 'animate.css';

const About = () => {
  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0
  });
  const [cursorVariant, setCursorVariant] = useState("default");

  useEffect(() => {
    const mouseMove = e => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY
      });
    };

    window.addEventListener("mousemove", mouseMove);

    return () => {
      window.removeEventListener("mousemove", mouseMove);
    };
  }, []);

  const controls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      // Adjust the scroll position based on your requirement
      if (scrollY > 200) {
        controls.start({
          opacity: 1,
          x: 0,
          transition: { duration: 0.8 },
        });
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [controls]);

  const textEnter = () => setCursorVariant("text");
  const textLeave = () => setCursorVariant("default");

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.8 } },
  };

  const textVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } },
  };

  const paragraphVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8, delay: 0.3 } },
  };

  return (
    <motion.section className='about section-p bg-dark' id="about" animate={controls} initial={{ opacity: 0, x: -20 }}>
      <motion.div>
        <TrackVisibility offset={50} partialVisibility>
          {({ isVisible }) => (
            <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
              <div className='container'>
                <motion.div className='about-content grid text-center'>
                  <motion.div className="content-left" variants={imageVariants} initial="hidden" animate="visible">
                    <img src={images.about_main_img} alt="" />
                  </motion.div>
                  <motion.div className='content-right'>
                    <motion.div className='section-t' variants={textVariants} initial="hidden" animate="visible">
                      <motion.div
                        className='cursor'
                       
                      />
                      <h3 
                      // onMouseEnter={textEnter} onMouseLeave={textLeave}
                      >Who we are?</h3>
                    </motion.div>
                    <motion.p className="text" variants={paragraphVariants} initial="hidden" animate="visible">
                      <strong style={{ color: 'orange' }}>Conprg Technology</strong> was founded in 2011 steadily grown into a company which offers complete solutions for IT development. We are a very young organization but with a wealth of experience across different spheres we bring a deep understanding of IT Industry with a sharp focus on the client.
                    </motion.p>
                    <motion.p className='text' variants={paragraphVariants} initial="hidden" animate="visible">
                      To be a globally respected corporation that provides best-of-breed business solutions, leveraging technology, delivered by best-in-class people." To achieve our objectives in an environment of fairness, honesty, and courtesy towards our clients, employees, vendors and society at large.
                    </motion.p>
                  </motion.div>
                </motion.div>
              </div>
            </div>
          )}
        </TrackVisibility>
      </motion.div>

    </motion.section>
  );
};

export default About;
