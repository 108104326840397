import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./Navbar.css";
import { FaTwitter, FaYoutube, FaFacebook } from "react-icons/fa";
import { IoMdRocket } from "react-icons/io";



const Navbar = () => {

    const [navToggle, setNavToggle] = useState(false);
    const navHandler = () => {
        setNavToggle(prevData => !prevData);
    }

    const logoname = [
        {
            "text": "C"
        },
        {
            "text": "O"
        },
        {
            "text": "N"
        },
        {
            "text": "P"
        },
        {
            "text": "R"
        },
        {
            "text": "G"
        }
    ]



    return (
        <nav className='navbar w-100 flex'>
            <div className='container w-100'>
                <div className='navbar-content flex fw-7'>
                    <div className='brand-and-toggler flex flex-between w-150'>
                        <Link to="/" className='navbar-brand fs-26'>
                            <div className='logo'>
                                {logoname.map((e) => (
                                    <div className='small-card'>
                                        <p className='logo-text' >{e.text}</p>
                                    </div>
                                ))}
                            </div>
                            <span className='logo-subtext'>Technologies (OPC) PVT LTD</span>
                        </Link>
                        {/* <span className='company-name'>Pvt Ltd</span>
                        <div className='logo-tag'>
                            <p>Continue Progress...</p>
                        </div> */}
                        <div type="button" className={`hamburger-menu ${navToggle ? 'hamburger-menu-change' : ""}`} onClick={navHandler}>
                            <div className='bar-top'></div>
                            <div className='bar-middle'></div>
                            <div className='bar-bottom'></div>
                        </div>
                    </div>

                    <div className={`navbar-collapse ${navToggle ? 'show-navbar-collapse' : ""}`}>
                        <div className='navbar-collapse-content'>
                            <ul className='navbar-nav'>
                                <li className='text-white'>
                                    <a href="#about">About</a>
                                    {/* <Link href="#about">About</Link> */}
                                </li>
                                <li className='text-white'>
                                <a href="#tech">Technologies</a>
                                    {/* <Link to="/">Features</Link> */}
                                </li>
                                <li className='text-white'>
                                <a href="#services">Services</a>
                                    {/* <Link to="/">Services</Link> */}
                                </li>
                                <li className='text-white'>
                                <a href="#contacts">Contacts</a>
                                    {/* <Link to="/">Contacts</Link> */}
                                </li>
                            </ul>
                            <ul className='navbar-social flex'>
                                <li className='text-white'>
                                    <Link to="" className='flex flex-center' ><FaTwitter /></Link>
                                    {/* <ReactTooltip /> */}
                                </li>
                                <li className='social-youtube text-white'>
                                    <a href="https://www.youtube.com/@techfive9447" className='flex flex-center'><FaYoutube /></a>
                                </li>
                                <li className='text-white'>
                                    <Link to="" className='flex flex-center'><FaFacebook /></Link>
                                </li>
                                {/* <ReactTooltip /> */}
                            </ul>
                            <div className='navbar-btns'>
                                <a href="#address" type="button" className='btn'><IoMdRocket /> <span>get started</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar